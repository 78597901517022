<template>
    <b-row>
        <div v-for="kategori in kategori" v-bind:key="kategori">
            <b-row @click="LoadDatakategori(kategori)" class="mt-1">
                <b-col md="4" v-if="kategori.kategori_titik_rawan == kategori_titik_rawan">
                    <a>
                        <b-avatar size="50" rounded="" variant="warning">
                            <span style="font-size:15px" class="text-white">{{ kategori.jm }}</span></b-avatar>
                    </a>

                </b-col>
                <b-col md="4" v-else>
                    <a>
                        <b-avatar size="50" rounded="" variant="light-warning">
                            <span style="font-size:15px" class="text-white">{{ kategori.jm }}</span></b-avatar>
                    </a>

                </b-col>
                <b-col md="8" class="text-warning" v-if="kategori.kategori_titik_rawan == kategori_titik_rawan">
                    <h6 class="text-warning"><small>Kategori Rawan</small></h6>
                    <h5 class="text-warning">{{ kategori.kategori_titik_rawan }}</h5>
                </b-col>

                <b-col md="8" class="text-white" v-else>
                    <h6 class="text-white"><small>Kategori Rawan</small></h6>
                    <h5 class="text-white">{{ kategori.kategori_titik_rawan }}</h5>
                </b-col>

            </b-row>
        </div>

    </b-row>
</template>

<script>
import {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
} from 'bootstrap-vue'

export default {
    components: {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    },
    props: {
        kategori: {

        }
    },
    data() {
        return {
            kategori_titik_rawan: ""
        }
    },
    computed: {

    },
    mounted() { },
    emits: ['LoadDatakategori'],
    methods: {
        LoadDatakategori(value) {
            this.kategori_titik_rawan = value.kategori_titik_rawan
            this.$emit("LoadDatakategori", value)
        },

    },
}
</script>